/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Khand', sans-serif;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font-family: 'Khand', sans-serif;
  font-size: 3rem;
  font-weight: 300;
  margin: 40px 0;
}

.mat-h2,
.mat-title,
.mat-typography h2,
.mat-card-title {
  font-family: 'Khand', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  margin: 30px 0;
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-table,
.mat-list-item,
.mat-toolbar,
.mat-card-subtitle,
.mat-chip,
.mat-button,
.mat-h3 {
  font-family: 'Khand', sans-serif;
}

.mat-raised-button {
  font-family: 'Khand', sans-serif;
  font-size: 1.5rem;
  padding: 20px;
}

.player-dialog .mat-dialog-container {
  padding: 0;
  background-color: black;
}

.uploader-status.mat-spinner {
  margin-right: 10px;

  circle {
    stroke: white;
  }
}

.pointer {
  cursor: pointer;
}

.preview-container {
  border-bottom: solid 2px #fff;
  position: absolute;
  background-repeat: no-repeat;
  display: none;
}

.shaka-bottom-controls {
  display: flex;
  flex-direction: column-reverse;
}

.preview-time {
  position: absolute;
  color: #fff;
  font-size: 12px;
  margin: auto;
  bottom: 5px;
  left: calc(50% - 25px);
}

.mat-drawer-inner-container  {
  width: 450px!important;
}